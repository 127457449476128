<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="15">
              <a-form-item label="话题名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入话题名称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--标签-->
              <a-col :md="5" :sm="15">
                <a-form-item label="标签" prop="label">
                  <a-select placeholder="请选择标签" style="width: 250px" v-model="queryParam.label" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TopicTypeEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!--是否系统默认-->
              <a-col :md="5" :sm="15">
                <a-form-item label="是否默认话题" prop="isDefault">
                  <a-select placeholder="是否默认话题" style="width: 250px" v-model="queryParam.label" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!--推荐-->
              <a-col :md="5" :sm="15">
                <a-form-item label="是否推荐" prop="isRecommend">
                  <a-select placeholder="请选择推荐" style="width: 250px" v-model="queryParam.label" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!--下架-->
              <a-col :md="5" :sm="15">
                <a-form-item label="是否下架" prop="isRecommend">
                  <a-select placeholder="请选择是否下架" style="width: 250px" v-model="queryParam.isOff" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="5" :sm="15">
                <a-form-item label="是否下架" prop="isOff">
                  <a-select v-model="queryParam.isOff" placeholder="请输入是否下架">
                    <a-select-option value="0">否</a-select-option>
                    <a-select-option value="1">是</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>-->
              <a-col :md="5" :sm="15">
                <a-form-item label="热度值" prop="hotValue">
                  <a-input v-model="queryParam.hotValue" placeholder="请输入热度值" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['community:topic:add']">
          <a-icon type="plus"/>
          新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
                  v-hasPermi="['community:topic:edit']">
          <a-icon type="edit"/>
          修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['community:topic:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['community:topic:export']">
          <a-icon type="download"/>
          导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['community:topic:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['community:topic:edit']">
            <a-icon type="edit"/>修改
          </a>
          <a-divider type="vertical" v-hasPermi="['community:topic:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['community:topic:remove']">
            <a-icon type="delete"/>删除
          </a>
        </span>
        <template slot="img" slot-scope="text, record">
          <div>
            <img v-if="record.img" :src="record.img" style="width:60px;height:62px;" @preview="handlePreview"/>
          </div>
        </template>
        <span slot="label" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TopicTypeEnum" :value="record.label"/>
        </span>
        <span slot="isRecommend" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（推荐）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isRecommend"/>
        </span>
        <span slot="isOff" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（下架）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isOff"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listTopic, delTopic} from '@/api/community/topic'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'Topic',
  components: {
    CreateForm,
    CustomDictTag
  },

  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        img: null,
        label: null,
        isRecommend: null,
        isOff: null,
        hotValue: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '话题名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片',
          dataIndex: 'img',
          scopedSlots: {customRender: 'img'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签',
          dataIndex: 'label',
          ellipsis: true,
          scopedSlots: { customRender: 'label' },
          align: 'center'
        },
        {
          title: '是否推荐',
          dataIndex: 'isRecommend',
          ellipsis: true,
          scopedSlots: { customRender: 'isRecommend' },
          align: 'center'
        },
        {
          title: '是否默认话题',
          align: "center",
          width: 120,
          dataIndex: 'isDefault',
          customRender: function (v) {
            if (v == 1) {
              return "是";
            } else if (v == 0) {
              return "否";
            }
          }
        },
        {
          title: '是否下架',
          dataIndex: 'isOff',
          ellipsis: true,
          scopedSlots: { customRender: 'isOff' },
          align: 'center'
        },
        {
          title: '热度值',
          dataIndex: 'hotValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询星河村-话题列表 */
    getList() {
      this.loading = true
      listTopic(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handlePreview(file) {
      console.log("打开了")
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        img: undefined,
        label: undefined,
        isRecommend: undefined,
        isOff: undefined,
        hotValue: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      const isDefault = row.isDefault

      this.$confirm({
        title: '确认删除所选中数据?',
        onOk() {
          if (isDefault) {
            alert('不能删除默认话题！')
            return
          } else {
            return delTopic(ids)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  '删除成功',
                  3
                )
              })
          }

        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('community/topic/export', {
            ...that.queryParam
          }, `topic_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
